import { McmisCarrierDto } from "./mcmisCarrier.dto";
import { McmisCrashDto } from "./mcmisCrash.dto";
import { McmisInspectionDto } from "./mcmisInspection.dto";
import { McmisViolationDto } from "./mcmisViolation.dto";

export class McmisSearchResponseDto {
    carrier?: McmisCarrierDto;
    crashes: McmisCrashDto[] = []
    inspections: McmisInspectionDto[] = []
    violations: McmisViolationDto[] = []
}
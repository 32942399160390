import { IdentifiableDto } from "./identifiable.dto";

export class McmisInspectionDto extends IdentifiableDto {
    uniqueId?: string;
    reportNumber?: string;
    reportState?: string;
    dotNumber?: string;
    inspDate?: Date;
    inspLevelId?: string;
    countryCodeState?: string;
    timeWeight?: string;
    driverOosTotal?: string;
    vehicleOosTotal?: string;
    totalHazmatSent?: string;
    oosTotal?: string;
    hazmatOosTotal?: string;
    hazmatPlacardReq?: string;
    unitTypeDesc?: string;
    unitMake?: string;
    unitLicense?: string;
    unitLicenseState?: string;
    vin?: string;
    unitDecalNumber?: string;
    unitTypeDesc2?: string;
    unitMake2?: string;
    unitLicense2?: string;
    unitLicenseState2?: string;
    vin2?: string;
    unitDecalNumber2?: string;
    unsafeInsp?: string;
    fatiguedInsp?: string;
    drFitnessInsp?: string;
    subtAlcoholInsp?: string;
    vhMaintInsp?: string;
    hmInsp?: string;
    basicViol?: string;
    unsafeViol?: string;
    fatiguedViol?: string;
    drFitnessViol?: string;
    subtAlcoholViol?: string;
    vhMaintViol?: string;
    hmViol?: string;
}
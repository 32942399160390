// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  auth0_domain: 'legacy-management-dev.us.auth0.com',
  auth0_client_id: 'FQaZEieb24oQn9XmMGiNgorGcjyWwrcm',
  auth0_audience: "https://management-services.legacyschoolsapp-dev.com",
  auth0_logout_redirect: "https://admin.legacyschoolsapp.com",
  services_legacy_management: "https://management-services.legacyschoolsapp.com"
};

import { IdentifiableDto } from "./identifiable.dto";

export class DefinitionDto extends IdentifiableDto {
    name!: string;
    abbreviation!: string;
    description!: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    free!: boolean;
    website!: string;
}
export class AddSectionDto {

    title?: string;

    published: boolean = false;

    free: boolean = false;

    resourceId?: number;

    parentId?: number;

    referenceId?: string;

    sectionRange?: string;
}
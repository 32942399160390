import { SectionTypeDto } from "./sectionType.dto";

export class EditSectionDto {

    title?: string;

    content?: string;

    commentary?: string;

    interpretation?: string;

    published!: boolean;

    free!: boolean;

    summary?: string;

    osha?: string;

    referenceId?: string;

    sectionRange?: string;

    referenceType?: SectionTypeDto;
}
export class EditResourceDto {

    title?: string;

    description?: string;

    coverImageUrl?: string;

    published?: boolean;

    free?: boolean;
}
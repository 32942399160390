import { FileDto } from "./file.dto";

export class EditFileDto {
    name?: string;
    documentTypeId?: string;

    static fromFile(file?: FileDto) : EditFileDto {
        var edit = new EditFileDto();
        edit.name = file?.name;

        return edit;
    }
}